@import '../../../Variables.scss';

$blue : #57f;
$green: #2edd92;

.ProjectBadge{
    transition: all 0.5s ease;
    
    .badgeBack{
        width: 20vw;
        height: 30vw;
        background-color: white;
        
        border-radius: 1vw 1vw 10vw 10vw;
    
        z-index: 1;
        transition: all 0.5s ease;

        .insideLine
        {
            position: relative;
            top: 1.25vw;
            left: 1.25vw;
            width: 17vw;
            height: 26.5vw;
            
            border-style: solid;
            border-color:  $backColor;
            border-width: 0.3vw;
            border-radius: 0.75vw 0.75vw 10vw 10vw;
            
            z-index: 0;
            transition: all 0.5s ease;
            
            .bdage-title{
                position: absolute;
                cursor: default;
                margin: 8vw 0 0 50%;
                transform: translateX(-50%);
                white-space:normal;
                
                color: transparent;
                background: linear-gradient(185deg, $blue, $green );
                background-clip: text;
                
                font-weight: 700;
                font-size: 3vw;
                text-align: center;
                line-height: 1em;
                
                border-style: solid;
                border-width: 0 0 0.3vw;
                border-color: transparent;
                
                transition: all 0.5s ease;
            }

            .bdage-desc{
                position: absolute;
                cursor: default;
                margin: 10vw 0 0 50%;
                transform: translateX(-50%);
                width: 23vw;
                
                color: $sideColor;
                font-size: 1.4vw;
                text-align: center;
                white-space:normal;
                line-height: 2vw;

                visibility: hidden;
                opacity: 0;
                
                transition: opacity 0.5s ease-out;
                transition-delay: 0.3s;
            }
        }

        .infoSec{
            position: relative;
            margin: -1.25vw -2vw;
            width: 6vw;
            height: 4vw;

            border-radius: 2vw 1vw 0vw 2vw;
            background: linear-gradient(80deg, $blue, $green );
            float: right;

            z-index: 1;
            transition: width 0.5s ease;

            .infoCircle{
                position: absolute;
                margin: 0.5vw 0.5vw;                
                width: 2.5vw;
                height: 2.5vw;
                
                border-radius: 2vw;
                border-width: 0.3vw;
                border-style: solid;
                border-color: white;

                .info-symbol{
                    margin: 0;
                    font-weight: 700;
                    font-size: 2vw;
                    text-align: center;
                    color: white;
                }
            }

            .moreinfo{
                margin: 0.5vw 0 0 4.5vw;
                color: white;
                visibility: hidden;
                opacity: 0;
                
                transition: opacity 0.4s ease-out;
                transition-delay: 0.2s;
            }

            .infoSec-back{
                position: absolute;
                margin-top: 4vw;
                right: 0vw;
                width: 2vw;
                height: 3vw;
                
                background: linear-gradient(200deg, $blue, $green );
                border-bottom-right-radius: 2vw;
                
                transition: width 0.5s ease;
            }  
        }
    }

    .badgeArmor{
        position: absolute;
        top: 10vw;
        left: 0.5vw;
        width: 19vw;
        height: 20vw;
        
        border-radius: 1vw 1vw 10vw 10vw;
        background: linear-gradient(230deg, $blue, $green );
        
        z-index: -1;
        transition: all 0.5s ease;
    }
    
    .badgeBeak{
        position: absolute;
        top: 30vw;
        margin-left: 10vw;
        width: 0vw;
        height: 0vw;

        background: linear-gradient(230deg, #3fb2c0, #38c2ae);
        visibility: hidden;
        
        transition: all 0.5s ease;
        
        .langLogo{
            position: relative;
            margin-top: 50%;
            margin-left: 50%;
            width: 4vw;
            transform: translate(-50%, -50%)
                scale(0,0);
            
                transition: all 0.5s ease;
        }
    }

    &:hover{
        transform: scale(1.11,1.11);
        & > .badgeBack{
            width: 30vw;
            border-bottom-left-radius: 12vw;
            border-bottom-right-radius: 12vw;

            .infoSec{
                width: 15vw;

                .moreinfo{
                    visibility: visible;
                    opacity: 1;   
                }
            }
    
            .insideLine{
                width: 27vw;

                .bdage-title{
                    color: $sideColor;
                    margin-top: 3vw;
                    font-size: 2vw;
                    padding: 0.5vw;
                    border-color: $sideColor;
                }

                .bdage-desc{
                    visibility: visible;
                    opacity: 1;
                }
 
            }
        }

        & > .badgeArmor{
            top: 11.75vw;
            left: -1.25vw;
            width: 32.5vw;
            
            border-bottom-left-radius: 13.5vw;
            border-bottom-right-radius: 13.5vw;
        }

        & > .badgeBeak{
            top: 27vw;
            margin-left: 12vw;
            width: 7vw;
            height: 6.5vw;
            border-radius: 8vw;
            
            visibility: visible;

            .langLogo{
                transform: translate(-50%, -50%)
                            scale(1,1);
            }

        }
    } 
}