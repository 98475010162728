@import '../../../Variables.scss';
@import '../resume.module.scss';

.container{
    margin-bottom: 2vw;

    .year{
        margin-right: 4vw;
    }

    .header{
        font-size: $header-font-size;
        font-weight: $header-font-weight;
    }

    .education{
        font-size: 1.5vw;
        margin-left: 1vw;

        .college{
            font-size: 1.75vw;
            font-weight: 500;
            margin-right: 0.75vw;
        }

        .major{
            font-size: 1.25vw;
        }
    }
}

@media screen and (max-width: $phoneWidth) {
}