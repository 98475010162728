@import '../../Variables.scss';

$blue : #57f;
$green: #2edd92;

.ProjItem{
    position: relative;
    margin-bottom: 12vw;
    &.blur{
        filter: blur(0.25vw) brightness(0.9);
        .proj_body .proj_more{
            cursor: default;
        }
    }


    .proj_iconBack{
        position: absolute;
        margin: -5vw 0 0 10vw;
        width: 16vw;
        height: 18vw;
        
        border-radius: 1vw;
        background: linear-gradient(135deg, $blue, $green );
        
        z-index: 2;
        .my-atropos{
            width: 16vw;
            height: 18vw;
            border-radius: 1vw;
            
            .projIcon{
                z-index: 2;
                position: absolute;
                margin: 4vw 0 0 3vw;
            }
            
        }

    }
    
    .proj_body{
        position: relative;
        margin-left: 18vw;
        width: 70vw;
        
        border-radius: 1vw;
        background-color: white;
        box-shadow: 1vw 1vw 2vw 0.2vw rgba(41, 43, 65, 0.1);
        
        z-index: 1;
        transition: height 0.5s ease-in-out;

        &.close{
            height: 25vw;
           
            .proj_descExt{
                visibility: hidden;
                color: transparent;
            }
            
            .proj_more{
                top: 23vw;
                .down_icon{
                    margin-top: 1.2vw;
                }
            }
        }

        &.open{
            height: 45vw;
            
            .proj_descExt{
                visibility: visible;
                color: $sideColor;
            }

            .proj_more{
                top: 43vw;
                .down_icon{
                    margin-top: 0.90vw;
                    transform: rotate(180deg);
                }
            }
            
        }

        .ptags{
            position: absolute;
            margin-left: 10vw;
            
            .ptag{
                float: left;
                cursor: default;
                margin: 1.7vw 0 0 1.1vw;
                
                font-size: 1.5vw;
                background-color: $blue;
                color: white;
                
                padding: 0.1vw 1vw 0.1vw 1vw;
                
                border-style: solid;
                border-color: $blue;
                border-width: 0.3vw;
                border-radius: 2vw;
            }

            .pgittag{
                font-weight: 700;
                background-color: transparent;
                color: $blue;
                
                transition: all 0.4s ease;

                &:hover{
                    color: $green;
                    border-color: $green;
                }
            }
        }

        .pinfo{
            position: relative;
            top: 8vw;

            .proj_title{
                position: absolute;
                cursor: default;
                margin: 0.5vw 0 0 50%;
                transform: translateX(-50%);
                
                white-space: nowrap;
                font-weight: 700;
                font-size: 2.2vw;
                color: $sideColor;
                
                padding: 0 2vw 0.5vw;
                border-style: solid;
                border-width: 0 0 0.35vw;
            }

            .proj_desc{
                color: $sideColor;
                cursor: default;
                position: absolute;
                margin: 6vw 0 0 11vw;
                width: 50vw;
                line-height: 2.5vw;
            }

            .proj_descExt{
                transition: color 0.7s ease-in-out;
                transition-delay: 0.2s;
            }
        }

        .proj_more{
            position: absolute;
            margin-left: 33vw;
            width: 4vw;
            height: 4vw;

            border-radius: 4vw;
            background-color: $blue;

            cursor: pointer;
        
            transition: all 0.4s ease-in-out, top 0.5s ease-in-out;

            .down_icon{
                position: absolute;
                margin-left: 1vw;
                transition: all 0.4s ease-in-out;
            }

            &:hover{
                background: none;
                background-color: $green;
                transform: scale(1.1, 1.1);
            }
        }       
    }
}

@media screen and (max-width: $phoneWidth) {
    .ProjItem{
        margin-bottom: 15vw;
        &.blur{
            filter: blur(0.35vw) brightness(0.9);
        }
    
        .proj_iconBack{
            margin: -5vw 0 0 3vw;
            width: 18vw;
            height: 20vw;
            
            border-radius: 1vw;
            box-shadow: -0vw -0vw 1vw 0.2vw #E1EBF9;
            .my-atropos{    
                width: 18vw;
                height: 20vw;

                .projIcon{
                    margin: 5vw 0 0 4vw;
                }
            }
        }
        
        .proj_body{
            margin: 0 0 0 10vw;
            width: 85vw;
            border-radius: 1.5vw;
            
            z-index: 1;
            box-shadow: 1vw 1vw 2vw 0.2vw rgba(41, 43, 65, 0.1);
    
            &.close{
                height: 35vw;
    
                .proj_descExt{
                    visibility: hidden;
                    color: transparent;
                }
                .proj_more{
                    top: 32vw;

                    .down_icon{
                        margin-top: 1.5vw;
                    }
                }
                
            }
    
            &.open{
                height: 60vw;
    
                .proj_descExt{
                    visibility: visible;
                }

                .proj_more{
                    top: 57vw;
                    
                    .down_icon{
                        margin-top: 1vw;
                        transform: rotate(180deg);
                    }
                }
                
            }
    
            .ptags{
                position: absolute;
                margin-left: 13vw;
                .ptag{
                    font-size: 2vw;
                    margin: 2.5vw 0 0 1.5vw;
                    
                    padding: 0.1vw 1.2vw 0.1vw 1.2vw;
                    border-width: 0.4vw;
                }

                .pgittag{
                    &:hover{
                        color: $blue;
                        border-color: $blue;
                    }
                }
            }

            .pinfo{
                top: 13vw;
                .proj_title{
                    font-size: 3vw;
                }
    
                .proj_desc{
                    margin: 7.5vw 0 0 7vw;
                    width: 73vw;
                    font-size: 2.2vw;
                    line-height: 3vw;
                }
            }
    
            .proj_more{
                position: absolute;
                margin-left: 39.5vw;
                width: 6vw;
                height: 6vw;

                border-radius: 5vw;
    
                .down_icon{
                    height: 3vw;
                    width: 3vw;
                    margin-left: 1.5vw;
                }

                &:hover{
                    background-color: $blue;
                    transform: none;
                }
            }       
        }
    }
}