@import '../../Variables.scss';

.container{
    position: absolute;
    top: 3vw;
    right: 3vw;

    z-index: 3;    
}

@media screen and (max-width: $phoneWidth) {
    .container{
        right: 3vw;
        top: 3vw;
    }
}