@import '../../Variables.scss';

.container{
    margin-right: 4vw;
    display: inline-block;
    
    .foot-link{
        &:hover + .underline{
            width: 100%;
        }
    }

    .underline{
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        width: 0%;
        height: 0.2vw;
        background-color: white;
        border-radius: 1vw;
        
        transition: width 0.4s ease;
    }
}

.last{
    margin-right: 0;
} 

@media screen and (max-width: $phoneWidth) {
    .link-container{
        margin-right: 3vw;   
    }

    .last{
        margin-right: 0;
    } 
}