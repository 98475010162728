@import '../../../../Variables.scss';
$blue: #00ffc9;
$orange: #f1cf00;
$pink: #d349ff;

.paint{
    .paint-drip{
        position: absolute;
        width: 13vw;
        height: 34vw;
        border-radius: 20vw;
        background: linear-gradient(20deg, $blue 0%, $pink 50%, $orange 100%);

        z-index: -1;
    }

    .left-paint{
        top: 1.5vw;
        left: 11vw;
        
        animation: floater 4s ease infinite;
    }

    .middle-paint{
        top: 7vw;
        left: 43.4vw;
        
        animation: floater 3.5s ease infinite;
    }

    .right-paint{
        top: 19vw;
        left: 76vw;
        height: 40vw;

        animation: floater 5s ease infinite;
    }

    .paint-drop{
        position: absolute;
        top: 43vw;
        left: 17vw;
        width: 6vw;
        height: 6vw;

        border-radius: 25vw;
        background: linear-gradient(20deg, $blue 0%, $pink 100%);
        
        animation: floater-slow 4s ease infinite;
    }

    .top-left-drip{
        position: absolute;
        top: 10vw;
        left: 16.25vw;
        width: 6.5vw;
        height: 22vw;
        
        border-radius: 20vw 8vw 25vw 10vw;
        background: linear-gradient(20deg, $blue 0%, $pink 70%, #E7A64C 100%);
        
        z-index: 1;
        animation: floater-slow 4s ease infinite;
    }

    .top-right-drip{
        position: absolute;
        top: 18vw;
        left: 77.5vw;
        width: 6.5vw;
        height: 17vw;

        border-radius: 25vw 8vw 16vw 6vw;
        background: linear-gradient(20deg, $pink 00%, $orange 100%);

        animation: floater-slow 5s ease infinite;
    }
}

@media screen and (max-width: $phoneWidth) {
    .paint{
        .paint_drip{
            width: 16vw;
            height: 34vw;
        }
        
        .left_paint{
            top: 1.5vw;
            left: 2vw;        
            height: 43vw;
        }

        .middle_paint{
            top: 12vw;
            left: 43.4vw;        
            height: 50vw;
        }
        
        .right_paint{
            top: 26vw;
            left: 82vw;
            height: 44vw;    
        }
        
        .paint_drop{
            top: 60vw;
            left: 13vw;
            width: 6vw;
            height: 6vw;
        }

        .top_left_drip{
            top: 17vw;
            left: 7vw;
            width: 10vw;
            height: 25vw;
            
            border-radius: 20vw 6vw 25vw 8vw;
            background: linear-gradient(20deg, $blue 0%, $pink 60%, #dd9172 100%);    
        }
        
        .top_right_drip{
            top: 25vw;
            left: 83vw;
            width: 9vw;
            height: 31vw;
            
            border-radius: 25vw 6vw 16vw 6vw;
            background: linear-gradient(20deg, #7e95eb 00%, $pink 20%, $orange 100%);
        }
    }
}

/* ------- Animation  -------*/
@keyframes floater{
    0%, 100%{ transform: translate(0vw, 0vw);}
    50%{ transform: translate(0vw, 0.55vw);}
}

@keyframes floater-slow{
    0%, 100%{ transform: translate(0vw, 0vw);}
    50%{ transform: translate(0vw, 0.3vw);}
}