@import '../../Variables.scss';

.container{
    position: relative;
    width: 100vw;
    height: 12vw;
    max-width: 100%;
    background-color: $mainColor;
    
    .foot-logo{
        position: relative;
        top: -5vw;
        left: 28.2vw;
        width: 0;
        
        .n-back, .d-back{
            position: absolute;
            top: 4vw;
            width: 1vw;
            height: 1vw;

            background-color: $mainColor;
            z-index: -1;

        }

        .n-back{
            left: 18vw;           
        }

        .d-back{
            left: 23vw;
            border-top-right-radius: 1vw;
        }
    }
    
    .foot-links{
        position: absolute;
        top: 5vw;
        left: 50vw;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 1.5vw;
               
    }
    
    .foot-copyright{
        position: absolute;
        transform: translateX(-50%);
        left: 50vw;
        top: 8vw;
        
        .copyright{
            cursor: default;
            font-size: 1.2vw;
            color: $sideColor;
        }
    }
}

@media screen and (max-width: $phoneWidth) {
    .container{
        height: 17vw;
    
        .foot-links{
            top: 7vw;
            font-size: 2.2vw;
        }
        
        .foot-copyright{
            top: 12vw;

            .copyright{
                font-size: 1.5vw;
            }
        }
    }
}