@import '../../Variables.scss';

.Projects{
    .badge_container{
        position:relative;
        margin-top: 12vw;
        margin-bottom: 13vw;
    }

    .projCover{
        position: absolute;
        top: -12vw;
        width: 100%;
        height: 125%;

        background: linear-gradient(#E1EBF9 0%, #6a7199 12%);
        opacity: 0%;
        
        z-index: 0;
        transition: opacity 0.2s ease-in;
        
        &.vis{
            opacity: 20%;
        }
    }
}

@media screen and (max-width: $phoneWidth) {
    .Projects{
        .badge_container{
            margin-top: 18vw;
            margin-bottom: 15vw;
        }

        .projCover{
            height: 120%;
        }
    }
}