@import '../../Variables.scss';

.home{
    z-index: -1;
    overflow: hidden;

    #head_logo{
        position: absolute;
        margin: 3vw 0 0 -12.1vw;
        z-index: 1;
    }

    #title_box {
        position: relative;
        margin: 23vw 0 0 50vw;
        width: 61vw; 
        height: 219w;
        transform: translate(-50%, -50%);
        
        border-radius: 4vw;
        background: #e8f0fa;
        box-shadow: 3vw 3vw 3vw 0.1vw rgba(41, 43, 65, 0.1);
        padding: 2vw;
        
        text-align: center;
    
        h2{
            font-weight: 600;
        }

        .hide, .my_title, .my_slogan{
            cursor: default;
            color: $mainColor;
        }

        .my_slogan{
            .hidden_link{
                font-weight: 600;
                width: 0;
                padding: 0.2vw 0;
                color: $sideColor;
                border-radius: 0.25vw;
                
                transition: all 0.4s ease;

                &:hover{
                    color: white;
                    margin: 0 0.5vw;
                    padding: 0 1vw;

                    &.in{ background-color:#0a66c2; }
                    &.be{ background-color: #053eff; }
                    &.git{ background-color: #333; }

                    &.ig{
                        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
                    }
                }
            }
        }
        
        .my_name{
            cursor: default;
            font-size: 5vw;
            margin: 0vw;
            color: $sideColor;
        }
    }
    
    /* ------- Projects -------*/
    .myprojects{
        position: relative;
        margin-bottom: 45vw;
        #project-label{
            text-align: center;
            font-size: 3vw;
            color: $sideColor
        }
    
        .projectBadges{
            position: absolute;
            margin-top: -1vw;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            
            .leftBadge, .rightBadge, .middleBadge{   
                display: inline-block;
            }
            
            .middleBadge{
                position: relative;
                margin: 0 8.5vw;
                top: 4vw;
            }
        }
    }

    /* ------- About Me -------*/
    .aboutMe{
        position: relative;
        margin: 30vw 0 20vw;
        #aboutMe-label{
            position: absolute;
            margin-left: 61vw;
            font-size: 3vw;
            color: $sideColor
        }
    }
}


@media screen and (max-width: $phoneWidth) {
    .home{
        #head_logo{
            margin: -4vw 0 0 -22.25vw;
            animation: floater 4s ease infinite;
            
            .my_logo{
                width: 22vw;
                height: 22vw;
            }
        }
        
        
        #title_box {
            margin-top: 36.5vw;   
            width: 77vw; 
            height: 30vw;
            
            border-radius: 5vw;
            padding: 3vw;    
            
            .my_slogan{
                margin-top: 5vw 0 1vw;
            }

            .my_title{
                margin-top: 1.5vw;
            }

            .my_name{
                font-size: 8.5vw;
            }

            h2{
                font-size: 3vw;
            }
        }

        .myprojects{
            margin-bottom: 55vw;

            #project-label{
                font-size: 3.5vw;
            }
        
            .projectBadges{
                margin-top: 1vw;
            }
        }

        /* ------- About Me -------*/
        .aboutMe{
            margin: 40vw 0 33vw;
            #aboutMe-label{
                font-size: 3.5vw;
                margin-left: 63vw;
            }
        }
    }
}

/* ------- Animation  -------*/
@keyframes glow{
    0%, 100%{ color: $mainColor;}
    50%{ color: $sideColor;}
}