@import '../../../Variables.scss';

.me-right{
    position: absolute;
    top: 8.5vw;
    left: 40vw;
    width: 45vw;
    height: 28vw;
    background: transparent;

    transition: transform 0.4s ease-out;
    .my-atropos{
        width: 60vw;
        height: 40vw;;
    }
    &.back{
        z-index: 0;
        &:hover{
            .mrBack{
                transform: scale(1, 1);
                transition: all 0.3s ease-out;
                
                .shadow-container .mr-shadow{
                        filter: blur(0.45vw);
                        opacity: 0.7;
                        transition: all 0.4s ease-out;
                        transform: scale(0.95, 0.92);
                }

                .mrt1{
                    transform: scale(1.02, 1.02);
                    transform-origin: center;
                    transform-box: fill-box;
                    transition: all 0.4s ease-out;
                }
            }

            .mrSqr{
                transform: translate(0vw, -0.7vw) 
                scale(1.05, 1.05)
                rotate(35deg);
            }

            .mr-CrcBr{
                .mrCrc{
                    transform: translate(1vw, -0.5vw) 
                    scale(1.1, 1.1);
                }  
            }

            .mrTrg{
                transform: translate(-0.5vw, 0.5vw) 
                scale(1.07, 1.07)
                rotate(-38deg);
            }
        }

        .mrBack{
            transform: scale(0.95, 0.95);
            cursor: pointer;
            .shadow-container{
                .mr-shadow{
                    opacity: 0.6;
                    width: 22.25vw;
                    height: 24.8vw;
                    transition: all 0.4s ease-out, width 0.4s ease-out;
                    transition-delay: 0s, 0.5s;                
                }
            }

            .mrt1{
                color: $sideColor;
                opacity: 1;
            }

            .mrt2{
                opacity: 0;
            }

            .mright-para{
                visibility: hidden;
            }
        }

        .mrSqr{
            transform: rotate(30deg);
            background: $mainColor;
            border:0.60vw solid $sideColor;
            cursor: pointer;
        }

        .mr-CrcBr{
            .mrBar{
                top: 10vw;
                height: 5vw;
                background: $sideColor;
            }

            .mrCrc{
                background: $mainColor;
                border:0.60vw solid $sideColor;
                cursor: pointer;
            }
        }

        .mrTrg{
            transform: rotate(-35deg);
            border-bottom: 5vw solid $sideColor;
            cursor: pointer;

            .mriTrg{
                border-bottom: 3vw solid $mainColor;
                cursor: pointer;
            }
        }
    }

    &.front{
        z-index: 1;

        .mrBack{
            animation: flip-bg 1s ease;
            animation-fill-mode: forwards; 
            transform: scale(1,1);
            cursor: default;
            .shadow-container .mr-shadow{
                opacity: 0;
                width: 0vw;
                height: 24vw;
                transition: all 0s ease-out;
            }

            .mrt1{
                color: transparent;
                opacity: 0;

                animation: flip-title1 1s ease;
            }

            .mrt2{
                opacity: 1; 

                animation: flip-title2 1s ease;
            }

            .mright-para{
                visibility: visible;

                animation: flip-para 1s ease;
            }
        }
        
        .mrSqr{
            transform: translate(-5vw, -1vw) 
            scale(1.4, 1.4)
            rotate(220deg);
            background: #9282EE;
            border:0.60vw solid white;
            cursor: default;
            
            animation: flip-square 1s ease;
        }

        .mr-CrcBr{
            transform-origin: right;
            transform: translate(3.2vw, 1.5vw);

            animation: flip-cirbar 1s ease;
            .mrBar{
                top: 5.2vw;
                height: 16vw;
                background: white;
                
                animation: flip-bar 1s ease;
            }

            .mrCrc{
                transform: scale(1.3, 1.3);
                background: #30D7D6;
                border:0.60vw solid white;
                cursor: default;
                
                animation: flip-circle 1s ease;
            }
            
        }

        .mrTrg{
            transform: translate(-10vw, 3vw) 
                scale(1.3, 1.3)
                rotate(-200deg);
            border-bottom: 5vw solid white;
            cursor: default;
            
            animation: flip-triangle 1s ease;

            .mriTrg{
                border-bottom: 3vw solid #50BADE;
                cursor: default;

                animation: flip-itriangle 1s ease;
            }
        }
    }

    .mrSqr{
        position: absolute;
        left: 35vw;
        width: 2.5vw;
        height: 2.5vw;
        transition: all 0.4s ease-out;      
    }

    .mr-CrcBr{
      
        .mrBar{
            position: absolute;
            left: 41.2vw; 
            width: 1.3vw;
            border-radius: 10vw;
            transition: all 0.4s ease-out;
        }

        .mrCrc{
            position: absolute;
            left: 38.5vw;
            top: 9.7vw;
            width: 6vw;
            height: 6vw;
            border-radius: 50%;
            transition: all 0.4s ease-out;  
        }
    }

    .mrTrg{
        position: absolute;
        left: 22vw;
        top: 23vw;
        width: 0;
        height: 0;
        border-left: 2.5vw solid transparent;
        border-right: 2.5vw solid transparent;
        transition: all 0.4s ease-out;
        
        .mriTrg{
            position: relative;
            top: 1.3vw;
            right: 1.5vw;
            width: 0;
            height: 0;
            border-left: 1.5vw solid transparent;
            border-right: 1.5vw solid transparent;
        }
    }

    .mrBack{
        position: absolute;
        width: 45vw;
        height: 28vw;
        background: $mainColor;
        
        transition: transform 0.4s ease-out;
        .shadow-container .mr-shadow{
            position: absolute;
            filter: blur(0.5vw);
            left: -1vw;
            top: -1vw;
            background: $sideColor;
        }

        .mrt1{
            position: absolute;
            font-weight: 700;
            font-size: 5vw;
            top: -1vw;
            left: 25vw;
            width: 10vw;
            transition: transform 0.4s ease-out, opacity 1.5s;
        }

        .mrt2{
            position: relative;
            font-weight: 700;
            font-size: 3vw;
            width: 40vw;
            margin-left: 5vw;
            margin-bottom: 0vw;
            color: white;
            transition: transform 0.4s ease-out, opacity 0s;
        }

        .mright-para{
            position: relative;
            margin-top: 1vw;
            margin-left: 8vw;
            width: 30vw;

            line-height: 2vw;
            font-size: 1.4vw; 
            color: white;
        }
    }
}

@media screen and (max-width: $phoneWidth) {
    .me-right{
        top: 12vw;
        left: 38vw;
        width: 45vw;
        height: 28vw;
        
        &.back{
            &:hover{
                .mrBack{
                    transform: scale(1, 1);                    
                    .shadow-container .mr-shadow{
                            filter: blur(0.45vw);
                            opacity: 0.7;
                            transform: scale(0.95, 0.92);
                    }
    
                    .mrt1{
                        transform: scale(1.02, 1.02);
                        transform-origin: center;
                        transform-box: fill-box;
                    }
                }
    
                .mrSqr{
                    transform: translate(0vw, -2vw) 
                    scale(1.1, 1.1)
                    rotate(35deg);
                }
    
                .mr-CrcBr{
                    .mrCrc{
                        transform: translate(1.5vw, -1vw) 
                        scale(1.1, 1.1);
                    }  
                }
    
                .mrTrg{
                    transform: translate(-0.5vw, 0.5vw) 
                    scale(1.35, 1.35)
                    rotate(-38deg);
                }
            }

            .mrBack{
                transform: scale(0.95, 0.95);
                .shadow-container .mr-shadow{
                    opacity: 0.6;
                    width: 24.25vw;
                    height: 28.5vw;
                }    
            }
    
            .mrSqr{
                transform: rotate(30deg);
                border:0.8vw solid $sideColor;
            }
    
            .mr-CrcBr{
                .mrBar{
                    top: 10vw;
                    height: 11vw;
                }
    
                .mrCrc{
                    border:0.8vw solid $sideColor;
                }
            }
    
            .mrTrg{
                transform: rotate(-35deg)
                        scale(1.2, 1.2);
            }
        }
    
        &.front{    
            .mrBack{
                animation-fill-mode: forwards; 
                transform: scale(1,1);
                .shadow-container .mr-shadow{
                    width: 0vw;
                    height: 28.5vw;
                }    
            }
            
            .mrSqr{
                transform: translate(1.2vw, -1vw) 
                scale(1.4, 1.4)
                rotate(220deg);
                border:0.70vw solid white;                
                
                animation: flip-square-m 1s ease;
            }
    
            .mr-CrcBr{
                transform-origin: right;
                transform: translate(2.5vw, 5vw);    

                animation: flip-cirbar-m 1s ease;
                .mrBar{
                    top: 5vw;
                    height: 22vw;          
                    animation: flip-bar-m 1s ease;      
                }
    
                .mrCrc{
                    transform: scale(1.15, 1.15);
                    border:0.80vw solid white;
                    animation: flip-circle-m 1s ease;                 
                }
                
            }
    
            .mrTrg{
                transform: translate(-5vw, 4vw) 
                    scale(1.6, 1.6)
                    rotate(-230deg);
                border-bottom: 5vw solid white;
                animation: flip-triangle-m 1s ease;

                    
                .mriTrg{
                    border-bottom: 3vw solid #50BADE;
                    animation: flip-itriangle 1s ease;

                }
            }
        }
    
        .mrSqr{
            left: 40vw;
            width: 3.5vw;
            height: 3.5vw;
        }
    
        .mr-CrcBr{
            .mrBar{
                left: 49vw; 
                width: 1.5vw;
                border-radius: 10vw;
            }
    
            .mrCrc{
                left: 44vw;
                top: 10vw;
                width: 10vw;
                height: 10vw;
                border-radius: 50%;
            }
        }
    
        .mrTrg{
            left: 21vw;
            top: 30vw;
            
            .mriTrg{
                top: 1.3vw;
                right: 1.5vw;
            }
        }
    
        .mrBack{
            width: 53vw;
            height: 35vw;
            
            .shadow-container .mr-shadow{
                filter: blur(0.7vw);
            }
    
            .mrt1{
                font-size: 7vw;
                top: -4vw;
                left: 25vw;
                width: 10vw;
            }
    
            .mrt2{
                font-size: 4.5vw;
                width: 45vw;
                margin-left: 4vw;
                margin-bottom: 0vw;
            }
    
            .mright-para{
                margin-top: 1.5vw;
                margin-left: 7vw;
                width: 37vw;
                font-size: 1.7vw; 
                line-height: 2.5vw;
            }
        }
    }
}

/* ------- Animation  -------*/
/* Me-Right*/
@keyframes flip-square{
    0%{ 
        transform: translate(0vw, -0.7vw) 
        scale(1.05, 1.05)
        rotate(35deg);
        background: $mainColor;
        border:0.60vw solid $sideColor;
    }
    50%{
        background: $mainColor;
        border:0.60vw solid $sideColor;
    }
    60%{
        transform: translate(2vw, -10vw)
        scale(0.6, 0.6)
        rotate(55deg); 
    }

    100%{
        transform: translate(-5vw, -1vw) 
        scale(1.4, 1.4)
        rotate(220deg);
        background: #9282EE;
        border:0.60vw solid white;
    }
}

@keyframes flip-circle{
    0%{
        transform: scale(1.1, 1.1);
        background: $mainColor;
        border:0.60vw solid $sideColor;
    }
    50%{
        background: $mainColor;
        border:0.60vw solid $sideColor;
    }
    60%{
        transform: scale(1.55, 1.55); 
    }
    100%{
        transform: scale(1.3, 1.3);
        background: #30D7D6;
        border:0.60vw solid white;
    }
}

@keyframes flip-bar{
    0%{
        height: 5vw;
        top: 10vw;
    }
    0%, 50%{ 
        background: $sideColor; 
    }
    60%{
        top: 6vw;
        height: 14vw;
    }
    100%{
        background: white;
        top: 5.2vw;
        height: 16vw;
    }
}

@keyframes flip-cirbar{
    0%{
        transform: translate(1vw, -0.5vw) 
    }
    60%{
        transform: translate(1vw, -0.5vw)
            rotate(40deg)
    }
    100%{
        transform: translate(3.2vw, 1.5vw)
            rotate(0deg)
    }
}

@keyframes flip-triangle{
    0%{
        transform: translate(-0.5vw, 0.5vw) 
            scale(1.07, 1.07)
            rotate(-38deg);
        border-bottom: 5vw solid $sideColor;

    }
    50%{
        border-bottom: 5vw solid $sideColor;
    }
    60%{
        transform: translate(-12vw, 6vw) 
            scale(0.7, 0.7)
            rotate(-100deg);
    }
    100%{
        transform: translate(-10vw, 3vw) 
            scale(1.3, 1.3)
            rotate(-200deg);
        border-bottom: 5vw solid white;
    }
}

@keyframes flip-itriangle{
    0%, 50%{ border-bottom: 3vw solid $mainColor;}
    100%{ border-bottom: 3vw solid #50BADE;}
}


/* Me-Right-Mobile*/
@keyframes flip-square-m{
    0%{ 
        transform: translate(0vw, -0.7vw) 
        scale(1.05, 1.05)
        rotate(35deg);
        background: $mainColor;
        border:0.80vw solid $sideColor;
    }
    50%{
        background: $mainColor;
        border:0.80vw solid $sideColor;
    }
    60%{
        transform: translate(3vw, -14vw)
        scale(0.7, 0.7)
        rotate(55deg); 
    }

    100%{
        transform: translate(1.2vw, -1vw) 
                scale(1.4, 1.4)
                rotate(220deg);
                   
        background: #9282EE;
        border:0.70vw solid white;    
    }
}

@keyframes flip-circle-m{
    0%{
        transform: scale(1.1, 1.1);
        background: $mainColor;
        border:0.80vw solid $sideColor;
    }
    50%{
        background: $mainColor;
        border:0.80vw solid $sideColor;
    }
    60%{
        transform: scale(1.35, 1.35); 
    }
    100%{
        transform: scale(1.15, 1.15);
        background: #30D7D6;
        border:0.80vw solid white;
    }
}

@keyframes flip-bar-m{
    0%{
        top: 10vw;
        height: 11vw;
    }
    0%, 50%{ 
        background: $sideColor; 
    }
    60%{
        top: 5.5vw;
        height: 20vw;
    }
    100%{
        background: white;
        top: 5vw;
        height: 22vw;
    }
}

@keyframes flip-cirbar-m{
    0%{
        transform: translate(1vw, -0.5vw) 
    }
    60%{
        transform: translate(-8vw, -0.5vw)
            rotate(45deg)
    }
    100%{
        transform: translate(2.5vw, 5vw)
            rotate(0deg)
    }
}

@keyframes flip-triangle-m{
    0%{
        transform: translate(-0.5vw, 0.5vw) 
            scale(1.2, 1.2)
            rotate(-35deg);
            
        border-bottom: 5vw solid $sideColor;

    }
    50%{
        border-bottom: 5vw solid $sideColor;
    }
    60%{
        transform: translate(-12vw, 6vw) 
            scale(0.9, 0.9)
            rotate(-100deg);
    }
    100%{
        transform: translate(-5vw, 4vw) 
            scale(1.6, 1.6)
            rotate(-230deg);
        border-bottom: 5vw solid white;
    }
}