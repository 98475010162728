@import '../../../Variables.scss';
@import '../resume.module.scss';

.container{
    margin-bottom: 2vw;

    .header{
        font-size: $header-font-size;
        font-weight: $header-font-weight;
    }

    .skills{
        margin: auto;
        width: 70%;
        line-height: 2.5vw;

        .skill{
            font-weight: 500;
            margin-left: 3vw;
            font-size: 1.25vw;
        }
    }
}

@media screen and (max-width: $phoneWidth) {
    .container{
        .skills{
            .skill{
                margin-left: 4vw;
                font-size: 1.5vw;
            }
        }
    }
   
}