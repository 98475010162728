@import '../../../Variables.scss';

.container{
    display: grid;
    margin: 2vw 0;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr;
    row-gap: 1vw;
    font-size: 1.25vw;

    .header{
        font-weight: 700;
        margin-right: 1rem;
    }
    
    .link{
        color: $shadeColor;
        font-weight: 200;
    }
}

@media screen and (max-width: $phoneWidth) {
}
