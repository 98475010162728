@import '../../../Variables.scss';

@mixin eqSize($size) {
    width: $size;
    height: $size;
}

.me-left{
    position: relative;
    top: 2.5vw;
    left: 15vw;
    width: 45vw;
    height: 28vw;
    background: transparent;

    transition: transform 0.4s ease-out;

    &.back{
        z-index: 0;
        &:hover{
            .mlBack{
                transform: scale(1, 1);
                transition: all 0.3s ease-out;
               .shadow-container .ml-shadow{    
                    filter: blur(0.45vw);
                    opacity: 0.7;
                    transform: scale(0.95, 0.92);
               }
               
               .mlt1{
                transform: scale(1.02, 1.02);
                transform-origin: center;
                transform-box: fill-box;
                transition: all 0.4s ease-out;
               }
            }

            .circuit{
                .mlCrc1, .mlCrc3{ @include eqSize(2.2vw);}

                .mlCrc1{
                    top: -24.25vw;
                    left: -1.3vw;  
                }

                .mlCrc2{ @include eqSize(4.5vw);}
                .mlCrc3{ right: -43vw; }
                .mlBar1{ height: 21vw; }
                .mlBar2{ width: 39.5vw;}
            }
        }
        
        .mlBack{
            transform: scale(0.95, 0.95);
            cursor: pointer;
            .shadow-container .ml-shadow{
                opacity: 0.6;
                width: 22.25vw;
                height: 24.8vw;
                transition: all 0.4s ease-out, width 0.4s ease-out;
                transition-delay: 0s, 0.5s;
            }
            
            .mlt1{
                color: $sideColor;
                opacity: 1;
            }

            .mlt2{
                opacity: 0;
            }
         
            .mleft-para{
                visibility: hidden;
            }
         }

        .circuit{
            top: 23vw;
            left: 4vw;
            cursor: pointer;

            .mlCrc1, .mlCrc2, .mlCrc3{
                background: $mainColor;
            }

            .mlCrc1, .mlCrc3{
                @include eqSize(2vw);
                border:0.60vw solid $sideColor;
            }

            .mlCrc1{
                top: -23vw;
                left: -1.2vw; 
            }

            .mlCrc2{
                top: 0vw;
                right:-0.8vw;
                @include eqSize(3.5vw);
                
                border:0.80vw solid $sideColor;
            }

            .mlCrc3{
                bottom: -2vw;
                right: -42vw;     
            }

            .mlBar1{
                width: 0.8vw;
                height: 20vw;
            }

            .mlBar2{
                height: 0.8vw;
                width: 38.5vw;  
            }
        }
    }

    &.front{
        z-index: 1;
        .mlBack{
            &.firstTime{
                animation: flip-bg 0s ease;
                animation-fill-mode: forwards;

                .mlt1{ animation: flip-title1 0s ease; }
                .mlt2{ animation: flip-title2 0s ease; }
                .mleft-para{ animation: flip-para 0s ease; }
            }

            &.nthTime{
                animation: flip-bg 1s ease;
                animation-fill-mode: forwards;

                .mlt1{ animation: flip-title1 1s ease; }
                .mlt2{ animation: flip-title2 1s ease; }
                .mleft-para{ animation: flip-para 1s ease; }
            }

            transform: scale(1,1);
            cursor: default;
            .shadow-container{
                .ml-shadow{
                    opacity: 0;
                    width: 0vw;
                    height: 24vw;
                    transition: all 0s ease-out;
                }
            }
            
            .mlt1{
                visibility: hidden;
                opacity: 0;
            }

            .mlt2{
                opacity: 1;
            }
         
            .mleft-para{
                visibility: visible;
            }
         }

        .circuit{
            top: 25.1vw;
            left: 2vw;
            cursor: default;

            &.firstTime{
                animation: flip-circuit 0s ease;

                .mlCrc1{ animation: flip-LCir1 0s ease; }
                .mlCrc2{ animation: flip-LCir2 0s ease; }
                .mlCrc3{ animation: flip-LCir3 0s ease; }
                .mlBar1{ animation: flip-LBar1 0s ease; }
                .mlBar2{ animation: flip-LBar2 0s ease; }
            }

            &.nthTime{
                animation: flip-circuit 1s ease;
                
                .mlCrc1{ animation: flip-LCir1 1s ease; }
                .mlCrc2{ animation: flip-LCir2 1s ease; }
                .mlCrc3{ animation: flip-LCir3 1s ease; }
                .mlBar1{ animation: flip-LBar1 1s ease; }
                .mlBar2{ animation: flip-LBar2 1s ease; }
            }

            .mlCrc1, .mlCrc3, .mlCrc2{
                background: transparent;
            }

            .mlCrc1, .mlCrc3{
                @include eqSize(2.4vw);
                border:0.75vw solid white;
            }

            .mlCrc1{
                top: -30.50vw;
                left: -1.5vw;
            }

            .mlCrc2{
                top: -0.2vw;
                right:-1vw;
                @include eqSize(3.5vw);
                border:1vw solid white;
            }

            .mlCrc3{
                bottom: -2.1vw;
                right: -46.2vw;                
            }

            .mlBar1{
                width: 1vw;
                height: 27vw;
                background-position: bottom;
            }

            .mlBar2{
                height: 1vw;
                width: 42.1vw;
                background-position: left;
            }
        }
    }

    .circuit{
        position: absolute;
        transition: all 0.4s ease-out; 

        .mlCrc1, .mlCrc2, .mlCrc3, .mlBar1, .mlBar2{
            position: absolute;
            transition: all 0.4s ease-out;
        }

        .mlCrc1, .mlCrc3, .mlCrc2{
            z-index: 1; 
            border-radius: 50%;
        }

        .mlCrc2{
            border-top-right-radius: 0%;
            transition: all 0.4s ease-out, border-color 0.4s ease-out;
            transition-delay: 0.0s, 0.26s;
        }

        .mlBar1{
            bottom: 0vw;
            left: 0vw; 
            background: linear-gradient(to bottom, $sideColor 50%, white 50%) top;
            background-size: 200% 200%;            
        }

        .mlBar2{
            bottom: -0.8vw;
            left: 0.5vw;
            background: linear-gradient(to left, $sideColor 50%, white 50%) right;
            background-size: 200%;
        }
    }

    .mlBack{
        position: absolute;
        width: 45vw;
        height: 28vw;
        background: $mainColor;

        transition: transform 0.4s ease-out;
        .shadow-container .ml-shadow{
                position: absolute;
                filter: blur(0.5vw);
                background: $sideColor;
                right: -1vw;
                bottom: -1vw;
        }
        
        .mlt1{
            position: absolute;
            font-weight: 700;
            font-size: 5vw;
            top: -2.5vw;
            left: 6.5vw;
            width: 10vw;

            transition: transform 0.4s ease-out, opacity 1.5s;
        }

        .mlt2{
            position: relative;
            font-weight: 700;
            font-size: 3vw;
            width: 40vw;
            margin-left: 5vw;
            margin-bottom: 0vw;
            margin-top: 2vw;
            color: white;

            transition: transform 0.4s ease-out, opacity 0s;
        }
     
        .mleft-para{
            position: relative;
            margin-top: 1vw;
            margin-left: 8vw;
            width: 33vw;
            font-size: 1.4vw;
            line-height: 2vw;
            color: white;
        }
    }
}

@media screen and (max-width: $phoneWidth) {
    .me-left{
        top: 2.5vw;
        left: 7vw;
        width: 45vw;
        height: 28vw;
        &.back{
            &:hover{
                .mlBack{
                    transform: scale(1, 1);
                    transition: all 0.3s ease-out;
                   .shadow-container .ml-shadow{
                        filter: blur(0.45vw);
                        opacity: 0.7;
                        transition: all 0.4s ease-out;
                        transform: scale(0.95, 0.92);
                   }
                   
                   .mlt1{
                    transform: scale(1.02, 1.02);
                    transform-origin: center;
                    transform-box: fill-box;
                    transition: all 0.4s ease-out;
                   }
                }
                
                .circuit{
                    .mlCrc1, .mlCrc3{
                        @include eqSize(3.2vw);
                    }

                    .mlCrc1{
                        top: -32.5vw;
                        left: -1.95vw;
                    }
    
                    .mlCrc2{
                        @include eqSize(5vw);
                    }
    
                    .mlCrc3{ right: -46vw; }
                    .mlBar1{ height: 28vw; }
                    .mlBar2{ width: 41vw; }
                }
            }
            
            .mlBack{
                transform: scale(0.95, 0.95);
                .shadow-container .ml-shadow{
                    width: 24.25vw;
                    height: 28.5vw;
                }
                
                .mlt1{
                    color: $sideColor;
                    opacity: 1;
                }
    
                .mlt2{
                    opacity: 0;
                }
             
                .mleft-para{
                    visibility: hidden;
                }
             }
    
            .circuit{
                top: 30vw;
                left: 4.5vw;
                
                .mlCrc1, .mlCrc3{
                    @include eqSize(3vw);
                    border:0.80vw solid $sideColor;
                }

                .mlCrc1{
                    top: -30.5vw;
                    left: -1.8vw;
                }
    
                .mlCrc2{
                    top: 0vw;
                    right:-0.8vw;
                    @include eqSize(4.5vw);
                    
                    border: 1vw solid $sideColor;
                }
    
                .mlCrc3{
                    bottom: -2.8vw;
                    right: -47vw;      
                }
    
                .mlBar1{
                    width: 0.8vw;
                    height: 26vw;
                }
    
                .mlBar2{
                    height: 0.8vw;
                    width: 42vw;  
                }
            }
        }
    
        &.front{
            .mlBack{
                transform: scale(1,1);
                .shadow-container .ml-shadow{
                    width: 0vw;
                    height: 28.5vw;
                }
                
                .mlt1{
                    visibility: hidden;
                    opacity: 0;
                }
    
                .mlt2{
                    opacity: 1;
                }
             
                .mleft-para{
                   visibility: visible;
                }
             }
    
            .circuit{
                top: 31.3vw;
                left: 3vw;
                
                &.firstTime{
                    animation: flip-circuit-m 0s ease;

                    .mlCrc1{ animation: flip-LCir1-m 0s ease; }
                    .mlCrc2{ animation: flip-LCir2-m 0s ease; }
                    .mlCrc3{ animation: flip-LCir3-m 0s ease; }
                    .mlBar1{ animation: flip-LBar1-m 0s ease; }
                    .mlBar2{ animation: flip-LBar2-m 0s ease; }
                }
    
                &.nthTime{
                    animation: flip-circuit-m 1s ease;

                    .mlCrc1{ animation: flip-LCir1-m 1s ease; }
                    .mlCrc2{ animation: flip-LCir2-m 1s ease; }
                    .mlCrc3{ animation: flip-LCir3-m 1s ease; }
                    .mlBar1{ animation: flip-LBar1-m 1s ease; }
                    .mlBar2{ animation: flip-LBar2-m 1s ease; }
                }

                .mlCrc1, .mlCrc3, .mlCrc2{
                    background: transparent;
                }
    
                .mlCrc1, .mlCrc3{
                    @include eqSize(3.5vw);
                    border:0.9vw solid white;
    
                }

                .mlCrc1{
                    top: -38vw;
                    left: -2.2vw;                   
                }
    
                .mlCrc2{
                    top: -0.2vw;
                    right:-1vw;
                    @include eqSize(5.5vw); 
                    border:1.2vw solid white;           
                }
    
                .mlCrc3{
                    bottom: -2.7vw;
                    right: -50.7vw;   
                }
    
                .mlBar1{
                    width: 1vw;
                    height: 33vw;
                    background-position: bottom;
    
                }
    
                .mlBar2{
                    height: 1vw;
                    width: 45.5vw;
                    background-position: left;
                }
            }
        }
    
        .circuit{
            .mlCrc1, .mlCrc3, .mlCrc2{
                border-radius: 50%;
            }
    
            .mlCrc2{
                border-top-right-radius: 0%;
            }
    
            .mlBar1{
                bottom: 0vw;
                left: 0vw; 
                background: linear-gradient(to bottom, $sideColor 50%, white 50%) top;
                background-size: 200% 200%;            
            }
    
            .mlBar2{
                bottom: -0.8vw;
                left: 0.5vw;
    
                background: linear-gradient(to left, $sideColor 50%, white 50%) right;
                background-size: 200%;
            }
        }

        .mlBack{
            width: 53vw;
            height: 35vw;    
            .shadow-container .ml-shadow{
                filter: blur(0.7vw);
                right: -1vw;
                bottom: -1vw;
            }
            
            .mlt1{
                font-size: 7vw;
                top: -4vw;
                left: 7vw;
                width: 10vw;
    
                transition: transform 0.4s ease-out, opacity 1.5s;
            }
    
            .mlt2{
                font-size: 4.5vw;
                width: 45vw;
                margin-left: 6vw;
                margin-bottom: 0vw;
            }
         
            .mleft-para{
                font-size: 1.7vw;
                margin-top: 1.5vw;
                margin-left: 9vw;
                line-height: 2.5vw;
                width: 41vw;
            }
        }
    
    }  
}

/* ------- Animation  -------*/
/* Me-left*/
@keyframes flip-circuit{
    0%{
        top: 23vw;
        left: 4vw;
    }
    100%{
        top: 25.1vw;
        left: 2vw;
    }
}

@keyframes flip-LCir1{
    0%{
        top: -24.25vw;
        left: -1.3vw;
        @include eqSize(2.2vw);
        background: $mainColor;
        border:0.60vw solid $sideColor;
    }
    50%{
        background: transparent;
    }
    75%{
        border:0.70vw solid $sideColor;
    }
    100%{
        top: -30.50vw;
        left: -1.5vw;
        @include eqSize(2.4vw);
        border:0.75vw solid white;
    }
}

@keyframes flip-LCir2{
    0%{
        @include eqSize(4.5vw);
        right:-0.7vw;
        background: $mainColor;
        border:0.80vw solid $sideColor;

    }
    50%{
        background: transparent;
        border:1vw solid $sideColor;
    }
    60%{
        border:1vw solid white;
    }
    100%{
        right:-1vw;
        top: -0.2vw;
        @include eqSize(3.5vw);
        border:1vw solid white;
    }
}

@keyframes flip-LCir3{
    0%{
        bottom: -2vw;
        right: -43vw;
        @include eqSize(2.2vw);
        border:0.60vw solid $sideColor;
    }
    50%{
        background: $mainColor;
    }
    75%{
        border:0.70vw solid $sideColor;
    }
    100%{
        bottom: -2.1vw;
        right: -46.2vw;
        @include eqSize(2.4vw);
        background: transparent;
        border:0.75vw solid white;
    }
}

@keyframes flip-LBar1{
    0%{
        height: 21vw;
        width: 0.8vw;
        background-position: top;
    }
    50%{
        background-position: top;
    }
    80%{
        background-position: bottom;
    }
    100%{
        height: 27vw;
        width: 1vw;
    }
}

@keyframes flip-LBar2{
    0%{
        height: 0.8vw;
        width: 39.5vw;
        background-position: right;
    }
    50%{
        background-position: right;
    }
    80%{
        background-position: left;
    }
    100%{
        height: 1vw;
        width: 42.1vw;
    }
}

/* Me-left-Mobile*/
@keyframes flip-circuit-m{
    0%{
        top: 30vw;
        left: 4.5vw;
    }
    100%{
        top: 31.3vw;
        left: 3vw;
    }
}

@keyframes flip-LCir1-m{
    0%{
        top: -32.5vw;
        left: -1.95vw;
        @include eqSize(3.2vw);
        background: $mainColor;
        border:0.8vw solid $sideColor;
    }
    50%{
        background: transparent;
    }
    75%{
        border:0.85vw solid $sideColor;
    }
    100%{
        top: -38vw;
        left: -2.2vw;
        @include eqSize(3.5vw);
        border:0.9vw solid white;
    }
}

@keyframes flip-LCir2-m{
    0%{
        @include eqSize(5vw);
        right:-0.8vw;
        background: $mainColor;
        border: 1vw solid $sideColor;

    }
    50%{
        background: transparent;
        border:1vw solid $sideColor;
    }
    60%{
        border:1vw solid white;
    }
    100%{
        right:-1vw;
        top: -0.2vw;
        @include eqSize(5.5vw); 
        border:1.2vw solid white;
    }
}

@keyframes flip-LCir3-m{
    0%{
        bottom: -2.8vw;
        right: -46vw;
        @include eqSize(3.2vw);
        border:0.8vw solid $sideColor;
    }
    50%{
        background: $mainColor;
    }
    75%{
        border:0.85vw solid $sideColor;
    }
    100%{
        bottom: -2.7vw;
        right: -50.7vw;
        @include eqSize(3.5vw);
        background: transparent;
        border:0.9vw solid white;
    }
}

@keyframes flip-LBar1-m{
    0%{
        height: 28vw;
        width: 0.8vw;
        background-position: top;
    }
    50%{
        background-position: top;
    }
    80%{
        background-position: bottom;
    }
    100%{
        height: 33vw;
        width: 1vw;
    }
}

@keyframes flip-LBar2-m{
    0%{
        height: 0.8vw;
        width: 41vw;
        background-position: right;
    }
    50%{
        background-position: right;
    }
    80%{
        background-position: left;
    }
    100%{
        height: 1vw;
        width: 45.5vw;
    }
}