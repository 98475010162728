@import '../../../Variables.scss';

.shadow-container{
    position: absolute;
    left: 0vw;
    top: 0vw;
    width: 45vw;
    height: 28vw;
    background: transparent;
    overflow: hidden;
}

@media screen and (max-width: $phoneWidth) {
    .shadow-container{
        left: 0vw;
        top: 0vw;
        width: 53vw;
        height: 35vw;
    }
}

/* ------- Animation  -------*/
/* Me-both*/
@keyframes flip-bg{
    0%{ transform: scale(1,1);
        background: $mainColor;
    }
    50%{ 
        transform: scale(0,1);
        background: #7a95ce;
    }
    51%{ 
        transform: scale(0,1);
        background: linear-gradient(320deg, #00ffc9 0%, #d349ff 80%); 
    }
    100%{ 
        transform: scale(1,1);
        background: linear-gradient(320deg, #00ffc9 0%, #d349ff 80%);
    }
}

@keyframes flip-title1{
    0%, 50%{
        opacity: 1;   
    }
    51%, 100%{
        opacity: 0;   
    }
}

@keyframes flip-title2{
    0%, 50%{
        opacity: 0;   
    }
    51%, 100%{
        opacity: 1;   
    }
    
}

@keyframes flip-para{
    0%, 50%{ visibility: hidden;}
    51%, 100%{ visibility: visible;}
}