@import '../../../Variables.scss';

.container{
    position: relative;
    margin: 1.5vw 0 0 2vw;
    float: left;
}

@media screen and (max-width: $phoneWidth) {
    .container{
        margin: 3vw 0 0 4vw;
    }
}