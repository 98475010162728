@import '../../Variables.scss';
$hiColor: #6786ca;

.contact{
    $inpFontS: 1.2vw;

    z-index: 0;
    .social-box{
        position: absolute;
        top: 7vw;
        left: 21vw;
    }

    .contact_box{
        position: relative;
        margin: 11.75vw 0 12vw 50vw;
        width: 50vw;
        height: 30vw;
        background-color: white;
        
        border-radius: 2vw;
        padding: 2vw;
        padding-top: 0.5vw;
        
        transform: translate(-50%);
        z-index: 1;

        input{
            width: 21vw;
            font-size: $inpFontS;
            border-radius: 0.5vw;
            border-width: 0.2vw;
            border-style: solid;
            border-color: $mainColor;
            padding: 0.75vw 1vw;
            
            &:focus{
                outline: none !important;
                border-color: $hiColor;
            }
        }
    
        .inp_label{
            cursor: default;
            font-size: 1.5vw;
            margin-left: 0.4vw;
            margin-bottom: 0.1vw;
            color: $sideColor;
        }
    
        .name_field, .email_field, .message_field{
            float: left;
        }
        
        .email_field{
            position: relative;
            margin-left: 2vw;
        }
        
        .message_field textarea{    
            width: 46.3vw;
            height: 17vw;
            padding: 0.75vw 1vw;
            border-radius: 0.5vw;
            border-width: 0.2vw;
            border-style: solid;
            font-size: $inpFontS;
            border-color: $mainColor;
            resize: none;

            &:focus{
                outline: none !important;
                border-color: $hiColor;
            }
        }
    
        .submit_button{
            position: absolute;
            margin: 31vw 0 0 -8vw;
            width: 8vw;
            height: 3vw;
            
            font-size: 1.5vw;
            font-weight: 700;
            background-color: $mainColor;
            color: white;
            
            border: none;
            border-radius: 2vw;
            
            cursor: pointer;
            transition: all 0.4s linear;
             
            &:hover{
                 background-color: $sideColor;
            }
         }
    }
}


@media screen and (max-width: $phoneWidth) {
    .contact{ 
        $inpFontS: 3vw;
        .social-box{
            top: 15vw;
            left: 3vw;
        }

        .contact_box{
            margin-top: 25vw;
            margin-bottom: 18vw;
            width: 77vw;
            height: 140vw;
            
            border-radius: 3vw;
            padding: 5vw;
            padding-top: 2vw;
    
            input{
                width: 72vw;
                padding: 1.5vw 2vw;
                border-radius: 0.7vw;

                font-size: $inpFontS;
                border-width: 0.4vw;
            }
        
            .inp_label{
                margin-bottom: 0.5vw;
                margin-left: 0.4vw;
                font-size: 3vw;
                color: $sideColor;
            }
        
            .name_field{
                float: none;
            }
            
            .email_field{
                margin-left: 0vw;
            }
            
            .message_field{        
                textarea{
                    width: 73vw;
                    height: 96vw;
                    padding: 1.5vw 2vw;
                    border-radius: 0.7vw;

                    border-width: 0.4vw;
                    font-size: $inpFontS;
                }
            }
        
            .submit_button{
                margin: 127vw 0 0 -20vw;
                width: 18vw;
                height: 8vw;
                
                font-size: $inpFontS;
                border-radius: 5vw;
             }
        }
    }
}