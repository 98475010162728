@import '../../../Variables.scss';

.container{
    .social-box{
        position: absolute;
        width: 18vw;
        height: 10vw;
        border-radius: 1.7vw;
        background-color: $mainColor;

        z-index: 1;
        
        .git-logo, .be-logo, .in-logo{
            margin-left: 2vw;
        }
    }
    
    $transition: all 0.4s ease-in-out;
    .email-box{
        position: absolute;
        overflow: hidden;
        margin-left: 12.75vw;
        height: 10vw;
        background-color: $sideColor;
        border-top-right-radius: 1.7vw;
        
        z-index: 0;
        transition: $transition;

        &.unext{
            width: 10vw;
            .my-email{
                margin-left: -15vw;
                visibility: hidden;
            }
        }

        &.ext{
            width: 30.5vw;
            .my-email{
                margin-left: 6.5vw;
                visibility: visible;
            }
        }

        .my-email{
            position: absolute;
            color: $mainColor;
            font-size: 1.5vw;
            margin-top: 1.5vw;

            transition: $transition;
        }

        .em-logo{
            position: absolute;
            margin-top: 1.5vw;
            right: 1.4vw;
            
            cursor: pointer;
            transition: $transition;
        }

        &:hover{
            &.unext{
                transform: translateX(0.65vw);
                .em-logo{
                    transform: scale(1.05,1.05);
                    right: 1.6vw;
                }
            }
        }
    }
}

@media screen and (max-width: $phoneWidth) {
    .container{
        $logoSize: 4vw;
        
        .contact_logo{
            width: $logoSize;
            height: $logoSize;
        }

        .social-box{
            width: 35vw;
            height: 20vw;
            border-radius: 1.7vw;
            
            .git-logo, .be-logo, .in-logo{
                margin-left: 4vw;
            }
        }
        
        .email-box{
            margin-left: 33vw;
            height: 20vw;

            &.unext{
                width: 12vw;
                .my-email{
                    margin-left: -33vw;
                }
            }
        
            &.ext{
                width: 49vw;
                .my-email{
                    margin-left: 4.5vw;
                }        
            }
        
            .my-email{
                margin-top: 3.25vw;
                font-size: 2.7vw;
            }
        
            .em-logo{
                margin-top: 3vw;
                right: 3vw;
            }

            &:hover{
                &.unext{
                    transform: None;
                    .em-logo{
                        transform: None;
                        right: 3vw;
                    }
                }
            }
        }
    }

}