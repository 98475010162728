.container{
    display: grid;
    grid-template-columns: 10% 90%;
    margin: 0 0 2vw 1vw;

    .date{
        font-weight: 500;
        font-size: 1.25vw;
    }

    .title{
        font-weight: 500;
        font-size: 1.75vw;
    }

    .location{
        margin-left: 0.5vw;
        font-size: 1.25vw;
    }
}